nav {
    margin-bottom: 20px;
    .uk-button {
        line-height: 18px;
        height: 40px;
    }
}
.uk-container-expand {
    margin: 0 20px;
}
.uk-form-label {
    padding: 2px 5px;
    display: block;
    background-color: #ccc;
    margin-bottom: 10px;
}
.gc-tabs {
    margin-top: 20px;
}
.gc-message {
    margin-top: 20px;
    border: dashed 2px #ccc;
    h2 {
        color: #aaa;
        font-style: italic;
    }
    p {
        margin-top: 0 !important;
    }
}
.gc-chart-vars {
    margin: 0 0 5px 0;
    padding-bottom: 5px;
    border-bottom: solid 1px #ccc;
    &:last-child {
        border-bottom: none;
    }
    div {
        padding-right: 20px;
        div {
            padding-right: 0;
        }
    }
    .gc-date {
        margin-right: 20px;
    }
}
.gc-container {
    margin: 10px 0;
    padding: 0 0 20px 0;
    width: 1200px;
    min-width: 1200px;
    height: 650px;
    min-height: 650px;
    .gc-selector {
        padding: 10px 0 0 0;
    }
    .gc-thumbnail {
        width: 120px;
        height: 50px;
        border: solid 1px #000;
        margin: 10px auto 0 auto;
    }
    .uk-card-body {
        padding: 0;
    }
    .gc-units {
        padding-right: 20px;
    }
    .gc-reset-button {
        margin-top: 2px;
    }
}